import React, { Suspense, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Route, Router, Switch } from 'react-router';
import { ConfigProvider } from 'antd';
import KeepAlive, { AliveScope } from 'react-activation';
import dayjs from 'dayjs';
import { app as App } from './index';
import Loading from '@/components/LoadingPage';
import AuthRoute from '@/components/AuthRoute';
import useLang from '@/uses/useLang';
import { LANG_LIB_MAP } from '@/constant'
import useTitle from '@/uses/useTitle';
import useSearchParams from '@/uses/useSearchParams';
import zhCN from 'antd/es/locale/zh_CN';

const requireComponent = require.context('./pages/', true, /route\.js$/);
const routes = requireComponent
.keys()
.reduce((routeArray, fileName) => {
  routeArray.push(...requireComponent(fileName).default);
  return routeArray;
}, []);

const saveRouteMatchParams = (props, app) => {
  const {
    match: { params, path },
    location,
  } = props;
  location.state = { ...location.state, match: { path } };
  app._store.dispatch({ type: 'app/saveHistory', payload: { params } });
};

export const renderRoutes = (
  routes,
  app = App,
  extraProps = {},
  switchProps = {},
) => {
  return routes ? (
    <Suspense fallback={<Loading />}>
      <Switch {...switchProps}>
        {routes.map((item, i) => (
          <Route
            key={item.name || i}
            path={item.path}
            exact={item.exact}
            strict={item.strict}
            render={(props) => routeRender(item, props, extraProps, app)}
          />
        ))}
      </Switch>
    </Suspense>
  ) : null;
};

export const routeRender = (
  route,
  props,
  extraProps,
  app,
) => {

  saveRouteMatchParams(props, app);
  if (route?.redirect) {
    return <Redirect to={route?.redirect} />;
  }
  // 判断渲染route
  let Component = route.render ? (
    route.render({ ...props, ...extraProps, route: route })
  ) : (
    <route.component {...props} {...extraProps} route={route} />
  );
  Component = route?.keepAlive ? (
    <KeepAlive {...(route?.keepAliveOptions ?? {})}>
      <route.component {...props} {...extraProps} route={route} />
    </KeepAlive>
  ) : (
    Component
  );
  return <AuthRoute route={route}>{Component}</AuthRoute>;
};

const RootCom = ({ history, app }) => {
  const { lang } = useLang();
  useTitle();
  useSearchParams();

const [antdLocale,setAntdLocale] = useState(zhCN);

useEffect(() => {
  import(`antd/es/locale/${LANG_LIB_MAP[lang]['antd']}.js`).then((local) => {
    setAntdLocale(local.default);
  });
}, [lang]);

useEffect(() => {
  const localStr = LANG_LIB_MAP[lang]['dayjs'];
  import(`dayjs/locale/${localStr}.js`).then(() => {
    dayjs.locale(localStr);
  });
}, [lang]);


  return (
    <Router history={history}>
      <AliveScope>
        <ConfigProvider locale={antdLocale}>{renderRoutes(routes, app)}</ConfigProvider>
      </AliveScope>
    </Router>
  );
};
export default function RouterConfig({ history, app }) {
  return <RootCom history={history} app={app} />;
}
