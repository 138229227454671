import { modelUtil } from '@/utils/model.util';
import { storageUtil } from '@/utils/storage.util';
import { getLangByBrowser } from '@/utils/lang.util'

const LANG_MODEL = {
  NAMESPACE: 'lang',
  REDUCERS: {
    UPDATE: 'update',
  },
  EFFECTS: {
    CHANGE_LANG: 'changeLang',
    INIT_LANG: 'initLang',
  },
  concatNamespace: type => `${LANG_MODEL.NAMESPACE}/${type}`,
};

const model = {
  namespace: LANG_MODEL.NAMESPACE,

  state: {
    lang: undefined,
  },
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(() => {
        dispatch({ type: 'initLang' });
      });
    },
  },

  reducers: {
    update: modelUtil.reducer.update,
  },

  effects: {
    initLang: function* (action, { put }) {
      const lang = storageUtil.local.get('lang');
      const browserLang = getLangByBrowser();
      yield put({
        type: LANG_MODEL.REDUCERS.UPDATE,
        payload: {
          $path: 'state.lang',
          value: lang || browserLang,
        },
      });
    },

    changeLang: function* (action, { put }) {
      yield put({
        type: LANG_MODEL.REDUCERS.UPDATE,
        payload: {
          $path: 'state.lang',
          value: action.payload,
        },
      });

      storageUtil.local.set('lang', action.payload);
    },
  },
};

export { LANG_MODEL };
export default model;
