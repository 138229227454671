import { useEffect, useMemo } from 'react';
import useLang from './useLang';
import { isEmpty } from 'lodash';
import { getLangByServerLang } from '@/utils/lang.util';
import Qs from 'qs';

const useSearchParams = () => {
  const { changeLang} = useLang();
  const searchParams = useMemo(() => {
    return Qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
  },[]);

  useEffect(() => {
    if (!isEmpty(searchParams)) {
      const { lang } = searchParams;
      if (!isEmpty(lang)) {
        changeLang(getLangByServerLang(lang));
      }
    }
  }, [searchParams]);
};

export default useSearchParams;
