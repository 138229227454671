import Base from './base';

const authAPI = {
  login(data) {
    return Base.POST('/api/lite/access/auth', { data });
  },
  getStoreBackstage(data) {
    return Base.POST('/api/lite/accesses', { data });
  },
  shutdown(data) {
    return Base.POST('/api/cam3d/v1/shutdown', { data });
  },
  getAuthenticatedInfo(data) {
    return Base.POST('/api/auth/authenticated', { data });
  }
};

export { authAPI };
