import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import ModalWithCtrl from '@/components/Modal/ModalWithCtrl';
import useLang from '@/uses/useLang';
import styles from './storageService.less';

import PaymentOrderModal from '@/businessComponents/PaymentOrderModal/PaymentOrderModal';
import useCloudServiceModal from './useCloudServiceModal';
import { storageUtil } from '@/utils/storage.util';
import { HIDDEN_CLOUD_SERVICE_HINT } from '@/constant/index';

const StorageServiceModal = (_, ref) => {
  const { lang, langCommon } = useLang();

  const [isFetch, setIsFetch] = useState(false);

  const {
    ready,
    getIsShowModal,
    uploadModalTime,
    getCloudServiceData,
    getStorageServiceStatus,
  } = useCloudServiceModal();

  const [isShowModalInfo, setIsShowModalInfo] = useState({});
  const modalCtrlRef = useRef(null);

  const openModal = () => {
    const hiddenModal = storageUtil.session.get(HIDDEN_CLOUD_SERVICE_HINT);
    if (hiddenModal) return;
    modalCtrlRef.current && modalCtrlRef.current.open();
    setIsFetch(true);
  };

  useEffect(() => {
    if (ready) {
      const info = getIsShowModal();
      setIsShowModalInfo(info);
      if (info.isShowModal) {
        openModal();
        uploadModalTime(info.type);
      }
    }
  }, [ready]);

  const [pathUrl] = useState(
    process.env.NODE_CUSTOM_ENV === 'production' ? 'https://m3d-mg.xiaofutech.com' : ' https://mg-test.myreal3d.com',
  );

  const paymentOrderModalCtrlRef = useRef(null);

  const onOpenPaymentPage = () => {
    modalCtrlRef.current.close();
    paymentOrderModalCtrlRef.current.open();
  };

  useImperativeHandle(ref, () => ({
    open: (refreshTime = false) => {
      openModal();
      refreshTime && uploadModalTime(isShowModalInfo.type);
    },
    openPaymentOrderModal: () => {
      setIsFetch(true);
      paymentOrderModalCtrlRef.current.open();
    },
  }));

  return (
    <>
      <PaymentOrderModal
        ctrlRef={paymentOrderModalCtrlRef}
        onRefresh={() => {
          setIsFetch(false);
          getStorageServiceStatus();
        }}
        isFetch={isFetch}
      />

      <ModalWithCtrl
        title={null}
        ctrlRef={modalCtrlRef}
        width={646}
        height={300}
        centered
        closable={false}
        cancelText={langCommon.gotIt(lang)}
        okText={langCommon.goBuy(lang)}
        onOk={onOpenPaymentPage}
        onCancel={() => setIsFetch(false)}
        okButtonProps={{
          className:
            (getCloudServiceData()?.is_disabled || getCloudServiceData()?.expiration_status === 3) &&
            styles.hideOkButton,
        }}
      >
        {() => {
          const CloudServiceData = getCloudServiceData() ?? {};
          const { title = '', message = '' } = CloudServiceData?.prompt?.[isShowModalInfo.type]?.copywriting ?? {};
          const capacityKeyWord = '云储存方案';
          const expirationKeyWord = '美际云后台';
          let messageHtml = message;
          if (messageHtml.includes(capacityKeyWord)) {
            const [before, after] = messageHtml.split(capacityKeyWord);
            messageHtml = (
              <>
                {before}
                <a onClick={onOpenPaymentPage}>{capacityKeyWord}</a>
                {after}
              </>
            );
          } else if (messageHtml.includes(expirationKeyWord)) {
            const [before, after] = messageHtml.split(expirationKeyWord);
            messageHtml = (
              <>
                {before}
                <a onClick={() => window.open(pathUrl)}>{expirationKeyWord}</a>
                {after}
              </>
            );
          }
          return (
            <div className={styles.storageServiceWrapper}>
              <div className={styles.greetings}>
                {langCommon.greetings(lang, { channelName: CloudServiceData?.channel_name })}
              </div>
              <div className={styles.storageServiceTitle}>{title}</div>
              <div className={styles.storageServiceDescription}>{messageHtml}</div>
            </div>
          );
        }}
      </ModalWithCtrl>
    </>
  );
};

export default React.forwardRef(StorageServiceModal);
