import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useLang from './useLang';

const useTitle = () => {
  const { lang ,langCommon} = useLang();
  const { config } = useSelector((state) => state.app);

  useEffect(() => {
    let title = config?.title?.[lang]
    document.title = title??langCommon.yanJia(lang);
  }, [config, lang]);
};

export default useTitle;
