import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ModalWithCtrl from '@/components/Modal/ModalWithCtrl';
import { useHTTP } from '@/uses/useHTTP';
import { StorageServiceAPI } from '@/service/storageService.api';
import { Spin } from 'antd';
import styles from './paymentPrderModal.less';
import { isFunction } from 'lodash';
import { storageUtil } from '@/utils/storage.util';
import { TOKEN_KEY_ENUM } from '@/constant';

PaymentOrderModal.propTypes = {
  ctrlRef: PropTypes.any,
  onRefresh: PropTypes.func,
};

PaymentOrderModal.displayName = 'PaymentOrderModal';

function PaymentOrderModal(props) {
  const { ctrlRef, onRefresh, isFetch } = props;

  const modalCtrlRef = useRef(null);

  const [paymentUrl, getPaymentUrlGetStatus, getPaymentUrl] = useHTTP(StorageServiceAPI.getPaymentUrl, {}, 'list');

  useEffect(() => {
    const token = storageUtil.local.get(TOKEN_KEY_ENUM);
    isFetch && token && getPaymentUrl({
      data: {
        isPC: 1, // 1: PC端
      }
    });
  }, [isFetch]);

  const open = () => {
    modalCtrlRef.current.open();
  };

  const close = () => {
    modalCtrlRef.current.close();
  };

  useEffect(() => {
    ctrlRef.current = {
      open,
      close,
    };
  }, []);

  return (
    <ModalWithCtrl
      ctrlRef={modalCtrlRef}
      centered
      footer={false}
      onCancel={() => {
        isFunction(onRefresh) && onRefresh();
      }}
      className={styles.paymentOrderModalWrapper}
    >
      {getPaymentUrlGetStatus.hasDone === false ? (
        <div data-layout-fill data-layout-align="center center">
          <Spin spinning={true} />
        </div>
      ) : (
        <iframe className={styles.iframeStyle} src={paymentUrl?.url} />
      )}
    </ModalWithCtrl>
  );
}

export default PaymentOrderModal;
