import { LOGIN_TYPE_ENUM } from '@/constant';

export const getConfig = ()=>{
    const config =  window.sessionStorage.getItem("config");
    const isEmbedded = window.localStorage.getItem(LOGIN_TYPE_ENUM) === 'loginByToken';
    try {
        const parseData = JSON.parse(config);
        if (isEmbedded) {
          const { embedded = {}, ...otherConfig } = parseData;
          return { ...otherConfig, ...embedded };
        }
        return parseData
    } catch (error) {
        console.log(error)
    }
    return {};
}
export const setConfig = (config)=>{
    return window.sessionStorage.setItem("config",JSON.stringify(config))
}
