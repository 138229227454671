import dva from 'dva';
import createLoading from 'dva-loading';
import router from './router';
import './styles/index.less';
import '@/styles/iconfont/iconfont.css';

const createBrowserHistory = require('history').createBrowserHistory;


export const app = dva({ history: createBrowserHistory() });
app.use(createLoading());

// Model
const requireComponent = require.context('./model/', false, /\.js$/);
requireComponent.keys()
.forEach((fileName) => {
  app.model(requireComponent(fileName).default);
});

//  Router
app.router(router); //eslint-disable-line

//  Start
app.start('#root');

// 热更新
if (module.hot) {
  module.hot.accept((err) => {
    if (err) {
      console.error('module.hot，', err);
    }
  });
}

const maxScreen = Math.max(window.screen.width, window.screen.height);
const ratio = maxScreen > 3000 ? 0.9 : 1;
const size = (maxScreen / 1920) * ratio;
document.querySelector('html').style['font-size'] = (size) + 'px';
if (maxScreen > 2200) {
  document.querySelector('html')
  .setAttribute('data-screen', '2k');
}
if (maxScreen > 3000) {
  document.querySelector('html')
  .setAttribute('data-screen', '4k');
}
