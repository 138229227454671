import React, { useState, useRef, useReducer, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'dva';
import { useDeepCompareEffect } from 'ahooks';
import qs from 'qs';

import StorageServiceModal from '@/businessComponents/StorageServiceModal/StorageServiceModal';
import { TOKEN_KEY_ENUM, HIDDEN_CLOUD_SERVICE_HINT } from '@/constant';

import { AuthPageContext } from '@/utils/context.util';
import { storageUtil } from '@/utils/storage.util';

const AuthRoute = (props) => {
  const { children, route } = props;
  const location = useLocation();
  const { config } = useSelector((state) => state.app);
  const [hasConfig, setHasConfig] = useState(false);
  const token = window.localStorage.getItem(TOKEN_KEY_ENUM);

  const [authPageContextValues, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'changeCloudServerData':
          return {
            ...state,
            cloudServerData: action.data,
          };
        default:
          return state;
      }
    },
    { cloudServerData: null },
  );

  useEffect(() => {
    if (location.pathname === '/') {
      const searchParams = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      if (searchParams.entry) {
        // 内嵌通过首页进入
        storageUtil.session.set(HIDDEN_CLOUD_SERVICE_HINT, true);
      }
    }
  }, []);

  useDeepCompareEffect(() => {
    setHasConfig(Object.keys(config ?? {}).length);
  }, [config]);

  const modalRef = useRef();

  if (route.authority === true) {
    // 路由需要登录权限
    // 是否已登录
    if (!token) {
      return <Redirect to="/login" />;
    }
  }
  return (
    <AuthPageContext.Provider
      value={{
        openCloudModal: modalRef.current?.open,
        openPaymentOrderModal: modalRef.current?.openPaymentOrderModal,
        ...authPageContextValues,
        dispatch,
      }}
    >
      <StorageServiceModal ref={modalRef} />
      {hasConfig ? children : null}
    </AuthPageContext.Provider>
  );
};

export default React.memo(AuthRoute);
