import _ from 'lodash';

const modelUtil = {
  reducer: {
    /**
     * @desc 任意更新一个对象中的任意层级的值
     *
     * @param state
     * @param action
     * @param action.payload
     * @param {string}action.payload.$path - 从 state 开始 比如 'state.list.data'
     * @param {*}action.payload.value
     * @param {boolean}action.payload.replace
     * @return {*}
     */
    update(state, action) {
      const stateCopy = _.cloneDeep(state);
      const { $path, value, replace } = action.payload;
      const valueCopy = _.cloneDeep(value);

      const pathArr = $path.split('.');

      if (pathArr.length === 1) {
        // 说明传入的 $path 是 'state'
        // 直接把 value 更新上去

        if (!_.isObject(valueCopy) || _.isArray(valueCopy)) {
          // 不是是纯对象 {};
          // 不做更新
          return stateCopy;
        }

        // 是纯对象
        if (replace) {
          // 要替换 则直接返回 value
          return valueCopy;

        } else {
          return {
            ...stateCopy,
            ...valueCopy,
          };
        }
      }

      let parentTarget = stateCopy;
      const lastPathName = pathArr[pathArr.length - 1];

      for (let i = 1; i < pathArr.length - 1; i++) {
        // 先设置默认值
        parentTarget[pathArr[i]] = parentTarget[pathArr[i]] || {};

        // 再把子元素递归上去
        parentTarget = parentTarget[pathArr[i]];
      }

      const target = parentTarget[pathArr[pathArr.length - 1]];

      if (_.isObject(target) && !_.isArray(target)) {
        // 纯对象{}
        // 根据 replace 来选择差量更新或替换更新
        if (replace) {
          parentTarget[lastPathName] = valueCopy;

        } else {

          if (!_.isObject(valueCopy)) {
            parentTarget[lastPathName] = valueCopy;

          } else {
            parentTarget[lastPathName] = {
              ...parentTarget[lastPathName],
              ...valueCopy,
            };
          }
        }

      } else {
        // 非纯对象 直接替换更新
        parentTarget[lastPathName] = valueCopy;
      }

      return stateCopy;
    },
  },
};

export { modelUtil };
