import langJSON from '@/language/language.json';
import { intlUtil } from '@/utils/intl.util';
import { storageUtil } from '@/utils/storage.util';
import { LANG_BROWSER_MAP, LANG_OBJ, LANG_SERVER_MAP } from '@/constant';

const defaultLang = ()=>  LANG_OBJ.en_US;

export const getLangFromLocal = () => {
  const lang = storageUtil.local.get('lang');
  return lang || getLangByBrowser();
};

// 从浏览器获取语言
export const getLangByBrowser = () => {
  let browserLang = window?.navigator?.language?.toLocaleLowerCase?.() ?? '';
  browserLang = browserLang?.replace(/-/g,'_');

  const [langInfo] =
  Object.entries(LANG_BROWSER_MAP).find(([, langs]) => {
    return langs.find((lang) => {
      const lowerCaseLang = lang.toLocaleLowerCase().replace(/-/g,'_');
      return browserLang.includes(lang) || lowerCaseLang.includes(browserLang);
    })?.length;
  }) ?? [];

  if (langInfo) return langInfo;
  return defaultLang();
};

export default intlUtil.proxy(langJSON);

export const getLangByServerLang = (serverLang) => {
  if(!serverLang) return defaultLang();
  const langInfo = Object.entries(LANG_SERVER_MAP)
  .find(([, serverKey]) => serverLang.toLocaleLowerCase() === serverKey.toLocaleLowerCase());
  if (langInfo) return LANG_OBJ[langInfo[0]];
  return defaultLang();
};
