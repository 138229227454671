import {useSelector,useDispatch} from 'dva';
import langCommon from '@/utils/lang.util';

import {LANG_MODEL} from '@/model/lang.model';
import { useCallback } from 'react';

const useLang = ()=>{
  const {lang} = useSelector(state=> state[LANG_MODEL.NAMESPACE]);
  const dispatch = useDispatch()

  const changeLang = useCallback((lang)=>{
    dispatch({
      type:`${LANG_MODEL.NAMESPACE}/${LANG_MODEL.EFFECTS.CHANGE_LANG}`,
      payload:lang
    })
  },[]);

  return {
    lang,
    langCommon,
    changeLang
  }


};

export default useLang;
