import _ from 'lodash';
// promise util

const pUtil = {
  /**
   * @param {promise|function} defer
   */
  interflow: (defer) => {
    if (_.isFunction(defer)) {
      return (...rest) => {
        return (
          defer(...rest)
          .then(
            res => [null, res.data],
            err => [err, null],
          )
        );
      };
    }

    return (
      defer
      .then(
        (res) => [null, res.data],
        (err) => [err, null],
      )
    );
  },

  interflowArgument: (defer) => {
    if (_.isFunction(defer)) {
      return (...rest) => {
        return (
          defer(...rest)
          .then(
            res => [null, res],
            err => [err, null],
          )
        );
      };
    }

    return (
      defer
      .then(
        (res) => [null, res],
        (err) => [err, null],
      )
    );
  },

  delay(time = 0) {
    return new Promise(resolve => {
      setTimeout(resolve, time);
    });
  },
};

export { pUtil };
