import { useState } from 'react';
import _ from 'lodash';

/**
 *
 * @param {function} api
 * @param {*} [defaultData]
 * @param {string | function} [resToData='data'] - 选择格式化 res 的方式 'data' 'records' 或者传入一个函数自定义格式
 * @return {[data, getStatus, http]}
 */
function useHTTP(api, defaultData, resToData = 'data') {

  const [data, setData] = useState(defaultData);

  const [getStatus, setGetStatus] = useState({
    hasDone: null,
    hasSuccess: null,
  });

  return [
    data,
    getStatus,
    http,
    reset,
    setData,
  ];

  function http(values) {
    setGetStatus({
      hasDone: false,
      hasSuccess: null,
    });

    return api(values)
    .then(
      (res) => {
        setData(() => {
          if (_.isFunction(resToData)) {
            return resToData(res);
          }

          if (resToData === 'data') {
            return res.data;
          }

          if (resToData === 'list') {
            return res.data.data;
          }

          return res;
        });

        setGetStatus({
          hasDone: true,
          hasSuccess: true,
        });

        return res;
      },
      (err) => {
        setGetStatus({
          hasDone: true,
          hasSuccess: false,
        });

        return Promise.reject(err);
      },
    );
  }

  function reset() {
    setData(defaultData);
    setGetStatus({
      hasDone: null,
      hasSuccess: null,
    });
  }
}

export { useHTTP };
