import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

Loading.propTypes = {
  loading: PropTypes.bool,
};

Loading.displayName = 'Loading';

function Loading(props) {
  const {
    loading = true,
  } = props;

  return (
    <div
      data-layout-fill
      data-layout-align="center center"
    >
      <Spin spinning={loading} />
    </div>
  );
}

export default Loading;
