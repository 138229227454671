import Base from './base';

const StorageServiceAPI = {
  // 云存储服务
  getStorageServiceStatus(data) {
    return Base.POST('/api/storage/service', { data });
  },
  // 获取支付页面地址
  getPaymentUrl(data) {
    return Base.POST(`/api/payment/recharge_page`, { data });
  },
};

export { StorageServiceAPI };
