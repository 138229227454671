/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */

import axios from 'axios'; // 引入axios
import Qs from 'qs'; // 引入qs模块，用来序列化post类型的数据
import { notification } from 'antd'; // 提示框
import { checkStatus, checkCustomCode } from './base'; // 附近处理函数
import { isFunction } from 'lodash';
import langCommon, { getLangFromLocal } from '@/utils/lang.util';

// 创建axios实例
const instance = axios.create({
  timeout: 15000, // 请求超时时间
  // `transformRequest` 允许在向服务器发送前，修改请求数据
  transformRequest: [
    function (data) {
      // 对 data 进行任意转换处理
      return data;
    },
  ],
  // `transformResponse` 在传递给 then/catch 前，允许修改响应数据
  transformResponse: [
    function (data) {
      // 对 data 进行任意转换处理
      return JSON.parse(data);
    },
  ],
  headers: {
    'Cache-Control': 'no-cache',
  },
});

// 实例添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在发送请求之前做处理...
    config.headers = Object.assign(
      {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
      },
      config.headers,
    );
    // config.headers.token = sessionStorage.getItem(`${projectPrefix}_token_`);

    if (config.method === 'post') {
      const contentType = config.headers['Content-Type'];
      // 根据Content-Type转换data格式
      if (contentType) {
        if (contentType.includes('multipart')) {
          // 类型 'multipart/form-data;'
          // config.data = data;
        } else if (contentType.includes('json')) {
          // 类型 'application/json;'
          // 服务器收到的raw body(原始数据) "{name:"nowThen",age:"18"}"（普通字符串）
          config.data = JSON.stringify(config.data);
        } else {
          // 类型 'application/x-www-form-urlencoded;'
          // 服务器收到的raw body(原始数据) name=nowThen&age=18
          config.data = Qs.stringify(config.data);
        }
      }
    }
    return Promise.resolve(config);
  },
  (error) =>
    // 对请求错误做处理...
    Promise.reject(error),
);

// 实例添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 对响应数据做处理，以下根据实际数据结构改动！！...
    return Promise.resolve(checkStatus(response));
  },
  (error) => {
    // 对响应错误做处理...
    if (error.response) {
      return Promise.reject(checkStatus(error.response));
    } else if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
      const lang = getLangFromLocal();
      return Promise.reject({ msg: langCommon.requestTimedOut(lang) });
    } else {
      return Promise.reject({});
    }
  },
);

const request = async (opt) => {
  const options = {
    method: 'get',
    ifHandleError: true, // 是否统一处理接口失败(提示)
    ...opt,
  };
  const lang = getLangFromLocal();
  try {
    const res = await instance(options);

    if (res.data.code && options.ifHandleError) {
      const { msg, errorModalAfter } = checkCustomCode(res);
      // 自定义参数，是否允许全局提示错误信息
      notification.error({
        message: msg || res.data.msg || langCommon.requestProcessingFailed(lang),
      });
      setTimeout(() => {
        isFunction(errorModalAfter) && errorModalAfter();
      }, 300);
      return Promise.reject({ msg });
    }
    return Promise.resolve(res);
  } catch (err) {
    if (options.ifHandleError) {
      // 自定义参数，是否允许全局提示错误信息
      notification.error({
        message: err.message || err.msg || langCommon.requestProcessingFailed(lang),
      });
    }
    return Promise.reject(err);
  }
};

export default request;
