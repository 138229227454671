import request from './index';
import { LOGIN_TYPE_ENUM, TOKEN_KEY_ENUM, LANG_SERVER_MAP } from '@/constant';
import { getConfig } from '@/utils/configFetch.util';
import langCommon, { getLangFromLocal } from '@/utils/lang.util';
import { storageUtil } from '@/utils/storage.util';

// 匹配接口前缀
export function getBaseUrl() {
  const configs = getConfig();

  // api_host 接口请求地址
  let baseUrl = configs?.api_host;
  // 如果 api_host_parse为1(为开启状态)，则获取浏览器location.hostname + 配置文件中的api_port作为请求地址
  if (Number(configs?.api_host_parse)) {
    baseUrl = `${window.location.protocol+'//'+window.location.hostname+':'+configs?.api_port}`;
    return baseUrl;
  }
  // 开发环境通过proxy配置转发请求；
  if(baseUrl && process.env.NODE_CUSTOM_ENV !=='development' ) return baseUrl
  switch (process.env.NODE_CUSTOM_ENV) {
    case 'development':
      // 开发环境通过proxy配置转发请求；
      baseUrl ='';
      break;
    case 'uat':
      // 测试环境
      baseUrl = 'https://test-service.myreal3d.com';
      break;
    case 'production':
      // 生产环境
      baseUrl = 'https://core-service-pro2.myreal3d.com';
      break;
    default:
      break;
  }
  return baseUrl;
}

export function checkCustomCode(result) {
  const lang = getLangFromLocal();
  // 如果http状态码正常，
  let customErrorMsg = '';
  let errorModalAfter = null;
  switch (result.data.code) {
    case 404:
      customErrorMsg = langCommon.systemError400(lang);
      break;
    case 429:
      customErrorMsg = langCommon.frequentOperation(lang);
      break;
    case 500:
      customErrorMsg = langCommon.systemError500(lang);
      break;
    case 604:
      customErrorMsg = langCommon.operationAuthorityRestriction(lang);
      break;
    case 605:
    case 606:
      customErrorMsg = langCommon.pleaseResetLogin(lang);
      errorModalAfter = () => {
        window.localStorage.removeItem(TOKEN_KEY_ENUM);
        window.localStorage.removeItem(LOGIN_TYPE_ENUM);
        window.location.href = '/login';
      };
      break;
    case 60101:
      customErrorMsg = langCommon.notAccountExist(lang);
      break;
    case 60102:
      customErrorMsg = langCommon.passwordError(lang);
      break;
    case 60103:
      customErrorMsg = langCommon.excessiveVerification(lang);
      break;
    case 60104:
      customErrorMsg = langCommon.accountLocked(lang);
      break;
    case 60105:
      customErrorMsg = langCommon.accountDeactivated(lang);
      break;
    case 60106:
      customErrorMsg = langCommon.notExistAccountRole(lang);
      break;
    case 60107:
      customErrorMsg = langCommon.accountNotAddedStore(lang);
      break;
    default:
      break;
  }
  return { result, msg: customErrorMsg, errorModalAfter };
}

export function checkStatus(response) {
  const status = response.status || -1000; // -1000 自己定义，连接错误的status
  if ((status >= 200 && status < 300) || status === 304) {
    if (response?.headers?.token) {
      window.localStorage.setItem(TOKEN_KEY_ENUM, response?.headers?.token);
    }
    return { data: response.data, headers: response?.headers };
  } else {
    // http status 不是200 错误
    let errorInfo = '';
    const lang = getLangFromLocal();
    switch (status) {
      case -1:
        errorInfo = langCommon.remoteServiceFailed(lang);
        break;
      case 400:
        errorInfo = langCommon.errorRequest400(lang);
        break;
      case 401:
        errorInfo = langCommon.accessTokenExpired401(lang);
        break;
      case 403:
        errorInfo = langCommon.accessDenied403(lang);
        break;
      case 404:
        errorInfo = langCommon.resourceNotFound404(lang);
        break;
      case 405:
        errorInfo = langCommon.requestNotAllowed405(lang);
        break;
      case 408:
        errorInfo = langCommon.requestTimedOut408(lang);
        break;
      case 500:
        errorInfo = langCommon.failedAccessService500(lang);
        break;
      case 501:
        errorInfo = langCommon.unrealized501(lang);
        break;
      case 502:
        errorInfo = langCommon.invalidGateway502(lang);
        break;
      case 503:
        errorInfo = langCommon.serviceUnavailable503(lang);
        break;
      default:
        errorInfo = langCommon.connectionError(lang);
    }
    return {
      status,
      msg: errorInfo,
    };
  }
}

class Base {
  mergeBaseOption(option = {}) {
    const headers = option?.headers ?? {};
    const token = window.localStorage.getItem(TOKEN_KEY_ENUM);
    const lang = getLangFromLocal();
    const XPhase = storageUtil.session.get('channel_environment')
    return { baseURL: getBaseUrl(), headers: { token, 'Accept-Language': LANG_SERVER_MAP[lang], "X-Phase": XPhase, ...headers }, ...option };
  }

  GET(url, option) {
    return request({ method: 'GET', url, ...this.mergeBaseOption(option) });
  }

  POST(url, option) {
    return request({ method: 'POST', url, ...this.mergeBaseOption(option) });
  }
}

export default new Base();
