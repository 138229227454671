import langCommon, { getLangFromLocal } from '@/utils/lang.util';

export const TOKEN_KEY_ENUM = '__mirage_user_token__';

export const ACCOUNT_ENUM = '__mirage_account__';

export const LOGIN_TYPE_ENUM = '__login_type__';

export const WATERMARK_SIZE_ENUM = '__watermark_size__';

// 知识库分类
export const KNOWLEDGE_TYPE_ENUM = {
  VIDEO: 1,
  PICTURE: 2,
};

export const KNOWLEDGE_CHANNEL_TYPE_ENUM = {
  ALL: 'ALL', // 所有
  CHANNEL: 'CHANNEL', // 机构
  MIRAGE: 'MIRAGE', // 美际
};

export const CASE_CHECK_ENUM = {
  BEFORE_LEFT_45: 'before_left_45',   //皮肤1 左45°图像
  BEFORE_CENTER: 'before_center',     //皮肤1 正脸图像
  BEFORE_RIGHT_45: 'before_right_45', //皮肤1 右45°图像
  AFTER_LEFT_45: 'after_left_45',     //皮肤2 左45°图像
  AFTER_CENTER: 'after_center',       //皮肤2 正脸图像
  AFTER_RIGHT_45: 'after_right_45',   //皮肤2 右45°图像
  BEFORE_CUSTOMIZED: 'before_customized', // 自定义
  AFTER_CUSTOMIZED: 'after_customized', // 自定义
};

export const USER_INFO_ENUM = {
  // 性别
  sexData: [
    {
      value: 2, label: () => {
        const lang = getLangFromLocal();
        return langCommon.female(lang);
      },
    },
    {
      value: 1, label: () => {
        const lang = getLangFromLocal();
        return langCommon.male(lang);
      },
    },
    {
      value: 0, label: () => {
        const lang = getLangFromLocal();
        return langCommon.privacy(lang);
      },
    },
  ],
  //是否已婚
  maritalData: [
    {
      value: 1, label: () => {
        const lang = getLangFromLocal();
        return langCommon.married(lang);
      },
    },
    {
      value: 2, label: () => {
        const lang = getLangFromLocal();
        return langCommon.unmarried(lang);
      },
    },
    {
      value: 0, label: () => {
        const lang = getLangFromLocal();
        return langCommon.privacy(lang);
      },
    },
  ],
  ageData: [
    {
      value: [0, 18], label: () => {
        const lang = getLangFromLocal();
        return langCommon.under18(lang);
      },
    },
    {
      value: [18, 25], label: () => {
        const lang = getLangFromLocal();
        return langCommon.to25(lang);
      },
    },
    {
      value: [25, 35], label: () => {
        const lang = getLangFromLocal();
        return langCommon.to35(lang);
      },
    },
    {
      value: [36, 45], label: () => {
        const lang = getLangFromLocal();
        return langCommon.to45(lang);
      },
    },
    {
      value: [45, 120], label: () => {
        const lang = getLangFromLocal();
        return langCommon.over45(lang);
      },
    },
  ],
};

export const TRIGGER_BACK_ENUM = {
  BLANK: 0, // 无返回(不显示返回按钮)
  NORMAL: 1, // 正常网页返回
  HANDLE: 2, // 其他处理
};

export const ACCOUNT_ROLE_ENUM = {
  NORMAL: 'NOMAL',
  LOG: 'LOG',
};

// 前端语言key
export const LANG_OBJ = {
  zh_CN: 'zh_CN',
  zh_HK: 'zh_HK',
  en_US: 'en_US',
  ja_JP: 'ja_JP',
  ko_KR: 'ko_KR',
};

// 浏览器语言key与前端语言key的映射
export const LANG_BROWSER_MAP = {
  [LANG_OBJ.zh_CN]: ['zh_CN'],
  [LANG_OBJ.zh_HK]: ['zh-tw', 'zh-hk', 'zh-mo','zh_Hant_MO'],
  [LANG_OBJ.en_US]: ['en_US', 'en'],
  [LANG_OBJ.ja_JP]: ['ja_JP','ja'],
  [LANG_OBJ.ko_KR]: ['ko_KR','ko'],
};

export const LANG_MAP = {
  [LANG_OBJ.zh_CN]: {
    lang: LANG_OBJ.zh_CN,
    label: '简体中文',
  },
  [LANG_OBJ.zh_HK]: {
    lang: LANG_OBJ.zh_HK,
    label: '繁体中文',
  },
  [LANG_OBJ.en_US]: {
    lang:  LANG_OBJ.en_US,
    label: 'English',
  },
  [LANG_OBJ.ja_JP]: {
    lang: LANG_OBJ.ja_JP,
    label: '日本語',
  },
  [LANG_OBJ.ko_KR]: {
    lang: LANG_OBJ.ko_KR,
    label: '한국어',
  },
};

// 第三方包对应的语言包
export const LANG_LIB_MAP = {
  [LANG_OBJ.zh_CN]: {
    antd: 'zh_CN',
    dayjs: 'zh-cn',
  },
  [LANG_OBJ.zh_HK]: {
    antd: 'zh_HK',
    dayjs: 'zh-hk',
  },
  [LANG_OBJ.en_US]: {
    antd: 'en_US',
    dayjs: 'en',
  },
  [LANG_OBJ.ja_JP]: {
    antd: 'ja_JP',
    dayjs: 'ja',
  },
  [LANG_OBJ.ko_KR]: {
    antd: 'ko_KR',
    dayjs: 'ko',
  },
};

// 前端语言key与后端语言key映射
export const LANG_SERVER_MAP = {
  [LANG_OBJ.zh_CN]: 'zh_Hans',
  [LANG_OBJ.zh_HK]: 'zh_Hant',
  [LANG_OBJ.en_US]: 'en',
  [LANG_OBJ.ja_JP]: 'ja',
  [LANG_OBJ.ko_KR]: 'ko',
};

export const CLOUD_SERVICE_TYPE = {
  DISABLED: 'is_disabled',
  WILL_EXPIRATION: 'will_expiration',
  EXPIRATION: 'is_expiration',
  DEADLINE: 'is_deadline',
  INSUFFICIENT: 'is_insufficient',
};

/** 是否展示云服务提示 session key */
export const HIDDEN_CLOUD_SERVICE_HINT = '__show_cloud_service_hint__';
