import { parse } from 'qs';
import { getConfig, setConfig } from '@/utils/configFetch.util';

const App = {
  namespace: 'app',
  state: {
    location: {
      query: {},
      params: {},
    },
    config: {},
    refresh: {
      list: false,
      detail: false,
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen((location) => {
        dispatch({ type: 'getConfigAndSet' });

        const { pathname, search = '?' } = location;
        const query = parse(search.substring(1));
        dispatch({
          type: 'saveHistory',
          payload: {
            pathname,
            query,
          },
        });
      });
    },
  },
  effects: {
    * getConfigAndSet(_, { put,select }) {
      const hasConfig = getConfig();
      const preConfig = yield select(store=>store.app.config)
      if (!Object.keys(hasConfig ?? {}).length) {
        const response = yield fetch(`/config.json?t=${new Date().getTime()}`);
        const parseData = yield response.json();
        // 从配置文件读取网页title
        setConfig(parseData);
        yield put({ type: "save", payload: { config: parseData } })
      } else if(!Object.keys(preConfig).length) {
        yield put({ type: "save", payload: { config: hasConfig } })
      }
    },
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
    saveHistory(state, { payload }) {
      const { location } = state;
      Object.keys(payload)
      .forEach((key) => {
        location[key] = payload[key];
      });
      const { query = {}, params = {} } = location;
      location.query = { ...query, ...params };
      return state;
    },
    saveRefresh(state, { payload }) {
      const { refresh } = state;
      return { ...state, refresh: { ...refresh, ...payload } };
    },
  },
};

export default App;
