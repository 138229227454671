import { lazy } from 'react';

export default [
  {
    path: '/',
    exact: true,
    component: lazy(() => import('@/pages/home/home')),
    authority: true,
    keepAlive: false,
  },
  {
    path: '/login',
    exact: true,
    component: lazy(() => import('@/pages/Auth/Login')),
    authority: false,
  },
  {
    path: '/loginByToken',
    exact: true,
    component: lazy(() => import('@/pages/Auth/Interpage')),
    authority: false,
  },
  {
    path: '/modifyPassword',
    exact: true,
    component: lazy(() => import('@/pages/modifyPassword/ModifyPassword')),
    authority: false,
  },
  {
    path: '/logView',
    exact: true,
    component: lazy(() => import('@/pages/logView/LogView')),
    authority: false,
  },
  {
    path: '/403',
    exact: true,
    component: lazy(() => import('@/pages/Exception/403')),
    authority: false,
  },
  {
    path: '/customer',
    exact: true,
    component: lazy(() => import('@/pages/custom/CustomList/customList')),
    authority: true,
    keepAlive: true,
    keepAliveOptions: {
      saveScrollPosition: 'screen',
    },
  },
  {
    path: '/access_customer',
    exact: true,
    component: lazy(() => import('@/pages/custom/CustomList/customList')),
    authority: false,
    keepAlive: true,
    keepAliveOptions: {
      saveScrollPosition: 'screen',
    },
  },
  {
    path: '/detail',
    exact: true,
    component: lazy(() => import('@/pages/custom/CustomDetail/customDetail')),
    authority: false,
    keepAlive: true,
    keepAliveOptions: {
      saveScrollPosition: 'screen',
    },
  },
  {
    path: '/access_detail',
    exact: true,
    component: lazy(() => import('@/pages/custom/CustomDetail/customDetail')),
    authority: false,
    keepAlive: true,
    keepAliveOptions: {
      saveScrollPosition: 'screen',
    },
  },
  {
    path: '/customs3D',
    exact: true,
    component: lazy(() => import('@/pages/custom/Custom3DImage/customImage')),
    authority: false,
  },
  {
    path: '/access_customs3D',
    exact: true,
    component: lazy(() => import('@/pages/custom/Custom3DImage/customImage')),
    authority: false,
  },
  {
    path: '/consult3D',
    exact: true,
    component: lazy(() => import('@/pages/custom/Consult3DImage/consultImage')),
    authority: false,
  },
  {
    path: '/case',
    exact: true,
    component: lazy(() => import('@/pages/case/case')),
    authority: false,
    keepAlive: true,
    keepAliveOptions: {
      saveScrollPosition: 'screen',
    },
  },
  {
    path: '/case-detail',
    exact: true,
    component: lazy(() => import('@/pages/case/detail')),
    authority: false,
  },
  {
    path: '/knowledge',
    exact: true,
    component: lazy(() => import('@/pages/knowledgeBase/knowledgeBase')),
    authority: false,
    keepAlive: true,
    keepAliveOptions: {
      saveScrollPosition: 'screen',
    },
  },
  {
    path: '/video',
    exact: true,
    component: lazy(() => import('@/pages/knowledgeBase/components/Content/videoPlayer')),
    authority: false,
  },
  {
    path: '/picture',
    exact: true,
    component: lazy(() => import('@/pages/knowledgeBase/components/Content/picturePreview')),
    authority: false,
  },
  {
    path: '/createUser',
    exact: true,
    component: lazy(() => import('@/pages/createUser/createUser')),
    authority: false,
  },
  {
    path: '/skinFilm',
    exact: true,
    component: lazy(() => import('@/pages/skinFilm/skinFilm')),
    authority: false,
  },
  {
    path: '/formDetail',
    exact: true,
    component: lazy(() => import('@/pages/custom/CustomDetail/FormDetail/FormDetail')),
    authority: false,
  },
  {
    path: '/demo',
    exact: true,
    component: lazy(() => import('@/pages/custom/Custom3DImage/components/Image/threejsDemo')),
    authority: false,
  },
];
