const storageUtil = {
  local: {
    set(key, item) {
      localStorage.setItem(key, toJSON(item));
    },
    get(key) {
      return toValue(localStorage.getItem(key));
    },
    remove(key) {
      localStorage.removeItem(key);
    },
  },
  session: {
    set(key, item) {
      sessionStorage.setItem(key, toJSON(item));
    },
    get(key) {
      return toValue(sessionStorage.getItem(key));
    },
    remove(key) {
      sessionStorage.removeItem(key);
    },
  },
};

function toJSON(item) {
  return typeof item !== 'string' ? JSON.stringify(item) : item;
}

function toValue(json) {
  if (json === 'undefined') {
    return undefined;
  }

  if (json === 'null') {
    return null;
  }
  try {
    return JSON.parse(json);
  } catch (error) {
    return json; // 不是有效的 JSON
  }
}

export { storageUtil };
