import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Modal } from 'antd';
import classNames from 'classnames';
import './withModal.less';
import useLang from '@/uses/useLang';
import { LoadingOutlined } from '@ant-design/icons';

ModalWithCtrl.propTypes = {
  ctrlRef: PropTypes.object,
  className: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  title: PropTypes.string,
  children: PropTypes.any,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  okButtonProps:PropTypes.object
};

ModalWithCtrl.displayName = 'ModalWithCtrl';

function ModalWithCtrl(props) {
  const {
    ctrlRef,
    width = 508,
    height,
    title = '标题',
    className,
    children,
    onCancel,
    onOk,
    maskClosable = false,
    cancelButtonProps,
    okButtonProps,
    onVisibleChange,
    ...restProps
  } = props;

  const { lang, langCommon } = useLang();

  const [isShow, setShowStatus] = useState(false);
  const [isSubmitting, setSubmittingStatus] = useState(false);
  const [modalProps, setModalProps] = useState({});
  const [childrenProps, setChildrenProps] = useState({});

  const allowedRestProps = _.pick(restProps, [
    'height',
    'okText',
    'cancelText',
    'zIndex',
    'footer',
    'mask',
    'style',
    'centered',
    'closable',
    'okButtonProps',
    'afterClose',
    'closeIcon',
  ]);

  useEffect(() => {
    if (ctrlRef) {
      ctrlRef.current = {
        open,
        close,
        setLoadingStatus,
        submit,
      };
    }
  });

  useEffect(() => {
    onVisibleChange?.(isShow);
  },[isShow])

  function footerRender() {
    return (
      <div
        className="footer"
      >
        <div onClick={() => {
          if (_.isFunction(cancelButtonProps?.onClick)) {
            cancelButtonProps?.onClick();
          } else {
            close();
          }
        }
        }>{allowedRestProps?.cancelText || langCommon.cancel(lang)}</div>
        <div
          onClick={() => {
            if (isSubmitting) return;
            submit();
          }}
          {...(okButtonProps??{})}
          className={classNames(isSubmitting && 'btnLoading' ,okButtonProps?.className)}
        >
          {isSubmitting && <LoadingOutlined className="loadingIcon" />}
          {allowedRestProps?.okText || langCommon.confirm(lang)}
        </div>
      </div>
    );
  }

  return (
    <Modal
      wrapClassName={classNames('withModal', className)}
      destroyOnClose
      title={title}
      width={Number(width) ? `${width}rem` : width}
      height={Number(height) ? `${height}rem` : height}
      visible={isShow}
      maskClosable={maskClosable}
      confirmLoading={isSubmitting}
      onOk={submit}
      onCancel={close}
      footer={footerRender()}
      {...allowedRestProps}
      {...modalProps}
    >
      {_.isFunction(children) ? children(childrenProps) : children}
    </Modal>
  );

  function close() {
    setSubmittingStatus(false);
    setShowStatus(false);
    _.isFunction(onCancel) && onCancel();
    setChildrenProps({});
    setModalProps({});
  }

  function open(modalProps = {}, childrenProps = {}) {
    setModalProps(modalProps);
    setChildrenProps(childrenProps);
    setShowStatus(true);
  }

  function submit() {
    _.isFunction(onOk) && onOk();
  }

  function setLoadingStatus(isLoading) {
    setSubmittingStatus(isLoading);
  }
}

export default ModalWithCtrl;
