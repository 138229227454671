import { pUtil } from '../utils/p.util';
import { authAPI } from '../service/auth.api';
import { TOKEN_KEY_ENUM, ACCOUNT_ENUM } from '@/constant';
import { routerRedux } from 'dva';

const AuthModel = {
  namespace: 'authModel',
  state: {
    token: null,
    account: null,
    channelUserInfo: {},
    permissions: {},
  },
  effects: {
    authLogin: function* ({ payload }, { put, call }) {
      const [, res] = yield call(pUtil.interflow(authAPI.login), payload);
      if (res) {
        yield put({
          type: 'save',
          payload: {
            token: window.localStorage.getItem(TOKEN_KEY_ENUM),
            account: res,
          },
        });
        window.localStorage.setItem(ACCOUNT_ENUM, JSON.stringify(res.data));
        yield put(routerRedux.push({ pathname: '/',  state: { entry: '/login' } }));
      }
    },
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default AuthModel;
