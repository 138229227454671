import { useContext, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { useHTTP } from '@/uses/useHTTP';
import { StorageServiceAPI } from '@/service/storageService.api';
import { storageUtil } from '@/utils/storage.util';

import { TOKEN_KEY_ENUM, ACCOUNT_ENUM, CLOUD_SERVICE_TYPE } from '@/constant';
import { AuthPageContext } from '@/utils/context.util';

const CLOUD_SERVICE_MODAL_INFO_KEY = 'cloudServiceModalInfo';
/** 是否展示了缴费周期弹窗  */
let SHOWED_EXPIRATION_MODAL = false;
// 存储结构
// const CLOUD_SERVICE_MODAL_INFO = {
//   organization_id: {
//     capacityLog: {
//       interval: 1,
//       preShowModalDate: '22',
//     },
//   },
// };

const getShowModalByDiffData = (startTime, endTime, diffDays) => {
  if (!startTime || !endTime) return true;
  // 将时间转为YYYY-MM-DD, 比较时间差,只比较天数，不比较时分秒
  const startTimeDay = dayjs(startTime).format('YYYY-MM-DD');
  const endTimeDay = dayjs(endTime).format('YYYY-MM-DD');
  const diff = dayjs(startTimeDay).diff(endTimeDay, 'd');
  return diff >= diffDays;
};

const NoModalPaths = ['/customs3D', '/access_customer', '/access_detail', '/access_customs3D', '/login'];

const useCloudServiceModal = () => {
  const { pathname } = useLocation();
  let userInfoMapRef = useRef({ token: '', userInfo: {} });

  const { dispatch } = useContext(AuthPageContext);

  const [ready, setReady] = useState(false); // 数据是否准备好
  const [storageServiceStatus, , getStorageServiceStatus] = useHTTP(
    StorageServiceAPI.getStorageServiceStatus,
    {},
    'list',
  );

  useEffect(() => {
    const token = storageUtil.local.get(TOKEN_KEY_ENUM);
    const userInfo = storageUtil.local.get(ACCOUNT_ENUM);
    const ignoreInitFetch = NoModalPaths.some((path) => pathname.includes(path));
    if (!token || !userInfo || ignoreInitFetch) return;
    userInfoMapRef.current = { token, userInfo };
    const { getStatusTime, storageServiceStatus } = getLocalStorage();
    // 第一次获取数据时，将时间默认设置为当前时间前12分钟，便于下面计算时间差，进行请求
    const preGetTime = dayjs(getStatusTime ?? dayjs().add(-12, 'm'));
    const diffMinute = dayjs().diff(preGetTime, 'm');
    // 距离上次请求大于1分钟时再次请求, 1-2分钟时按1分钟算，需要2分钟后重新请求
    if (diffMinute > 1) {
      getStorageServiceStatus();
    } else {
      dispatch({
        type: 'changeCloudServerData',
        data: storageServiceStatus,
      });
      setReady(true);
    }
  }, []);

  useEffect(() => {
    if (isEmpty(storageServiceStatus)) return;
    // 存储云服务接口请求时间和返回的数据
    uploadLocalStorage('storageServiceStatus', storageServiceStatus);
    uploadLocalStorage('getStatusTime', dayjs().format('YYYY-MM-DD HH:mm:ss'));
    setReady(true);
    dispatch({
      type: 'changeCloudServerData',
      data: storageServiceStatus,
    });
  }, [storageServiceStatus]);

  const getIsShowModal = () => {
    if (pathname !== '/') return { isShowModal: false, type: '' };
    const { token, userInfo } = userInfoMapRef.current;
    if (!token || isEmpty(userInfo)) return { isShowModal: false, type: '' };

    const { capacityLog, expirationLog, storageServiceStatus } = getLocalStorage();
    const { capacity, expiration } = storageServiceStatus?.prompt ?? {};
    if (!capacity && !expiration) return { isShowModal: false, type: '' };

    let info = { isShowModal: false, type: '' };
    if (expiration) {
      // 缴费周期
      const { preShowModalDate, interval: preInterval } = expirationLog ?? {};
      const { interval } = expiration;
      const showModal = preInterval !== interval || getShowModalByDiffData(dayjs(), preShowModalDate, interval);
      if (showModal) {
        // 记录当前标签弹出了缴费周期弹窗
        SHOWED_EXPIRATION_MODAL = true;
      }
      info = { isShowModal: showModal, type: 'expiration' };
    }
    // 未逾期 && 不弹缴费周期弹窗时 - 进入存储空间弹窗判断
    if (capacity && storageServiceStatus?.expiration_status < 2 && !info.isShowModal && !SHOWED_EXPIRATION_MODAL) {
      // 存储空间提示
      const { preShowModalDate, interval: preInterval } = capacityLog ?? {};
      const { interval } = capacity;
      const showModal = preInterval !== interval || getShowModalByDiffData(dayjs(), preShowModalDate, interval);
      info = { isShowModal: showModal, type: 'capacity' };
    }
    return info;
  };

  const getCloudServiceData = () => {
    return getLocalStorage()?.storageServiceStatus ?? {};
  };

  const uploadModalTime = (key) => {
    const { storageServiceStatus } = getLocalStorage();
    const interval = storageServiceStatus?.prompt?.[key]?.interval;
    const value = { interval, preShowModalDate: dayjs().format('YYYY-MM-DD HH:mm:ss') };
    uploadLocalStorage(`${key}Log`, value);
  };

  const getLocalStorage = () => {
    const info = storageUtil.local.get(CLOUD_SERVICE_MODAL_INFO_KEY) ?? {};
    const { organization_id } = userInfoMapRef.current.userInfo;
    return info[organization_id] ?? {};
  };

  const uploadLocalStorage = (key, value) => {
    const info = storageUtil.local.get(CLOUD_SERVICE_MODAL_INFO_KEY) ?? {};
    const { organization_id } = userInfoMapRef.current.userInfo;
    storageUtil.local.set(CLOUD_SERVICE_MODAL_INFO_KEY, {
      ...info,
      [organization_id]: {
        ...(info[organization_id] ?? {}),
        [key]: value,
      },
    });
  };

  return { ready, uploadModalTime, getIsShowModal, getCloudServiceData, getStorageServiceStatus };
};

export default useCloudServiceModal;
